import {
  Dropdown,
  IDropdown,
  IDropdownOption,
  ITextField,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  Toggle,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./Capture.css";
import { v4 as uuid } from "uuid";
import { AutocompleteSearchBox, RenderIf } from "../libs";
import { stackTokens } from "../common/styles/StackStyles";
import CaptchaDialog from "./CaptchaDialog";
import { IMetadata, OtherInfo } from "./OtherInfo";
import IssueOtherQuestions from "./IssueOtherQuestions";
import Camera from "./Camera";
import "../i18n/config";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import CameralRoll from "./CameralRoll";

interface ISubmitStatus {
  success: boolean;
  message: string;
  details?: ISuccessResponse;
}

interface IResponse {
  message?: string;
}

interface ISuccessResponse extends IResponse {
  issueUuid: string;
}

interface IServerError {
  detail?: string;
}

interface IWebcamCaptureParams {
  zone: string | null;
}

interface IIssueType {
  id: number;
  name: string;
}

interface IAssetCategory {
  id: number;
  name: string;
  code: string;
  issueTypes: IIssueType[] | null;
  subCategories: IAssetCategory[] | null;
}

interface IZone {
  id: number;
  name: string;
  assetCategories: IAssetCategory[] | null;
  tenantId: number;
}

const Capture = (params: IWebcamCaptureParams) => {
  const { register, handleSubmit } = useForm();

  const [status, setStatus] = useState<ISubmitStatus>();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [zone, setZone] = useState<IZone>();

  const emulationMode = searchParams.get("emulationMode");

  const needsCaptcha =
    (process.env.REACT_APP_NEEDS_CAPTCHA ?? "") === "true" && !emulationMode;
  const { t } = useTranslation();

  const [puzzleSolved, setPuzzleSolved] = useState(!needsCaptcha);

  const [
    isCaptchaDialogOpen,
    { setTrue: showCaptchaModal, setFalse: hideCaptchaModal },
  ] = useBoolean(false);
  const [images, setImages] = useState<string[]>([]);
  const [otherInfo, setOtherInfo] = useState({});
  const [state, setState] = useState("");
  const [anonymous, setAnonymous] = useState(true);

  const [zoneAssetCat, setZoneAssetCat] = useState<IAssetCategory>();
  const [zoneAssetSubCat, setZoneAssetSubCat] = useState<IAssetCategory>();
  const [zoneAssetSubCat2, setZoneAssetSubCat2] = useState<IAssetCategory>();

  const [issueTypeId, setIssueTypeId] = useState<number | undefined>(undefined);

  const [issueMetadataResponse, setIssueMetadataResponse] = useState();

  const nameInput = useRef(null);
  const mobileInput = useRef(null);
  const emailInput = useRef(null);
  const issueTypeInput = useRef(null);

  interface IAsset {
    code?: string;
    name?: string;
    uuid?: string;
    zoneId: number;
    supportedIssues?: IIssueType[];
    tenantId: number;
    otherInfo?: string;
  }

  interface IAssetResponse {
    asset: IAsset;
  }

  interface IConfig {
    logo: string;
    languages: string;
  }

  const [asset, setAsset] = useState<IAsset>({ zoneId: 0, tenantId: 0 });
  const [announcement, setAnnouncement] = useState<string[]>([]);
  const [config, setConfig] = useState<IConfig>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assetIsSearched, setAssetIsSearched] = useState(false);
  const [flattenAssetCats, setFlattenAssetCats] = useState(
    new Map<string, IAssetCategory>()
  );
  const [metadata, setMetadata] = useState<IMetadata>();
  const [suggestions, setSuggestions] = useState<string[]>();
  const flattenCats = (
    cats: IAssetCategory[] | null,
    map: Map<string, IAssetCategory>,
    path: string
  ) => {
    if (!cats) {
      return;
    }
    for (const cat of cats) {
      map.set(cat.name, cat);
      flattenCats(cat.subCategories, map, path);
    }
  };

  const fetchZoneData = async (): Promise<number> => {
    console.log("Loading zone details");
    setState("Loading zone details");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/v1/zones?id=${params.zone}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
        {
          redirect: "follow",
        }
      );
      if (response.status === 429) {
        throw new Error("TooManyRequests");
      }
      const data: IZone = (await response.json()) as IZone;
      console.log(data);
      setZone(data);
      const catsMap = new Map<string, IAssetCategory>();
      flattenCats(data.assetCategories, catsMap, "/");
      const nodesMap = new Map<string, IAssetCategory>();
      catsMap.forEach((v, k) => {
        if (v.subCategories?.length === 0) {
          nodesMap.set(k, v);
        }
      });
      setFlattenAssetCats(nodesMap);
      setState("");
      return Promise.resolve(data.tenantId);
    } catch (error: any) {
      setStatus({
        success: false,
        message: error.message.startsWith("TooManyRequests")
          ? "You have reached the number of issues you can report in 5 mins, please try again later"
          : `Couldn't get the item details. Error: ${error.message}`,
      });
      return Promise.reject(error);
    }
  };

  const fetchAssetData = async (): Promise<number> => {
    const code = searchParams.get("code");
    try {
      const filter = id ? `id=${id}` : `uuid=${code}`;
      setState("Loading asset details");
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/v1/assets?${filter}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
        {
          redirect: "follow",
        }
      );
      if (response.status === 429) {
        throw new Error("TooManyRequests");
      }
      const data = (await response.json()) as IAssetResponse;
      setAsset({ ...data.asset });

      const fetchHeaders = new Headers([
        ["tenantId", data.asset.tenantId?.toString()],
      ]);

      const metadataResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/v1/metadata?entity=asset&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
        { headers: fetchHeaders }
      );
      const metadata = await metadataResponse.json();
      setMetadata(metadata);
      const issueMetadataResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/v1/metadata?entity=issue&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
        { headers: fetchHeaders }
      );
      const issueMetadata = await issueMetadataResponse.json();
      setIssueMetadataResponse(issueMetadata);

      return Promise.resolve<number>(data.asset.tenantId);
    } catch (error: any) {
      setStatus({
        success: false,
        message: error.message.startsWith("TooManyRequests")
          ? "You have reached the number of issues you can report in 5 mins, please try again later"
          : `Couldn't get the item details. Error: ${error.message}`,
      });
      return Promise.reject(error);
    }
  };
  const fetchData = async () => {
    let tenantId: number | undefined = undefined;
    if (params.zone) {
      tenantId = await fetchZoneData();
    } else {
      tenantId = await fetchAssetData();
    }
    const fetchHeaders = new Headers([["tenantId", tenantId?.toString()]]);
    const configApiResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_API_URL}/v1/config?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
      { headers: fetchHeaders }
    );
    const configResponse = await configApiResponse.text();
    setConfig(JSON.parse(JSON.parse(configResponse)) as IConfig);

    const announcementResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_API_URL}/v1/announcement?target=C&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
      {
        redirect: "follow",
        headers: fetchHeaders,
      }
    );
    setAnnouncement(await announcementResponse.json());

    setState("");
  };

  useEffect(() => {
    fetchData();
  }, [id, searchParams, params.zone]);

  const zoneId = params.zone ? Number(params.zone) : asset.zoneId;
  const navigate = useNavigate();

  const submitIssue = async (data: any) => {
    setIsSubmitting(true);
    data = {
      ...data,
      uuid: uuid(),
      zoneId: zoneId,
      zoneAssetCat: zoneAssetSubCat2
        ? Number(zoneAssetSubCat2.id)
        : zoneAssetSubCat
        ? Number(zoneAssetSubCat.id)
        : zoneAssetCat
        ? Number(zoneAssetCat.id)
        : undefined,
      images: images,
      otherInfo: JSON.stringify(otherInfo),
      isAnonymous: anonymous,
      name: (nameInput?.current as unknown as ITextField).value,
      mobile: (mobileInput?.current as unknown as ITextField).value,
      email: (emailInput?.current as unknown as ITextField).value,
      issueTypeId: params.zone
        ? issueTypeId
        : asset.supportedIssues && asset.supportedIssues.length > 0
        ? Number(
            (issueTypeInput?.current as unknown as IDropdown).selectedOptions[0]
              .key
          )
        : undefined,
    };
    try {
      const response = await fetch(
        // "https://webhook.site/d7c2651d-be58-4d8e-a112-453f39ea9855",
        `${process.env.REACT_APP_BACKEND_API_URL}/v1/issues?${
          asset.uuid ? `uuid=${asset.uuid}` : `zone=${zoneId}`
        }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
          redirect: "follow",
        }
      );
      if (response.status === 429) {
        throw new Error("TooManyRequests");
      }
      if (response.status === 500) {
        const serverError = (await response.json()) as IServerError;
        throw new Error(serverError.detail);
      }
      const submitResponse: IResponse = (await response.json()) as IResponse;
      if ("issueUuid" in submitResponse) {
        const data: ISuccessResponse = submitResponse as ISuccessResponse;

        setStatus({
          success: true,
          message: `Issue created successfully: ${data.issueUuid}`,
          details: data,
        });
        if (!emulationMode) {
          navigate(
            `/success?issue=${data.issueUuid}&${
              params.zone ? `zone=${zoneId}` : `asset=${asset.uuid}`
            }`
          );
        }
      } else {
        setStatus({
          success: false,
          message: submitResponse.message ?? "",
        });
      }
    } catch (error: any) {
      setStatus({
        success: false,
        message: error.message.startsWith("TooManyRequests")
          ? "You have reached the number of issues you can report in 5 mins, please try again later"
          : `Error: ${error.message}`,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (data: any) => {
    submitIssue(data);
  };

  const labelColumnStyle = {
    width: 125,
    textAlign: "left" as const,
  };

  const formRef = useRef<HTMLFormElement>(null);

  const isSaved = useRef<boolean>(false);

  const handleCapture = useCallback((src) => {
    setImages((prevImages) => [...prevImages, src]);
  }, []);

  const handleRetake = useCallback((index) => {
    setImages((prevImages) => {
      const newImage = [...prevImages];
      newImage.splice(index, 1);
      return newImage;
    });
  }, []);
  return (
    <>
      <Stack horizontalAlign="center">
        {announcement?.map((a) => (
          <MessageBar messageBarType={MessageBarType.info}>{a}</MessageBar>
        ))}
      </Stack>
      <Stack horizontal horizontalAlign="center">
        <form ref={formRef}>
          <Stack tokens={stackTokens} className="insideForm">
            <p style={{ display: "none" }}>{t("Thanks")}</p>
            <RenderIf condition={!emulationMode}>
              <Stack horizontal>
                <img
                  src={config?.logo ?? process.env.REACT_APP_LOGO_URL}
                  alt="Logo"
                  className="App-logo"
                />
                <RenderIf condition={!!config?.languages}>
                  <LanguageSelector
                    options={config?.languages ?? process.env.REACT_APP_LANGS}
                  />
                </RenderIf>
              </Stack>
              <Text variant="large">{t("Thanks")}</Text>
            </RenderIf>
            <RenderIf condition={!!state}>
              <Text variant="smallPlus">{state}</Text>
            </RenderIf>
            <RenderIf condition={!!params.zone}>
              <Stack horizontal tokens={stackTokens}>
                <Label>Zone: </Label>
                <TextField type="text" placeholder={zone?.name} disabled />
              </Stack>
              <Text>Please type the Asset name</Text>
              <AutocompleteSearchBox
                suggestions={suggestions}
                onClear={() => setAssetIsSearched(false)}
                onChange={(_, newText) => {
                  if (!newText || newText.trim() === "") {
                    setSuggestions(undefined);
                  } else {
                    const cats = Array.from(flattenAssetCats.keys());
                    setSuggestions(
                      cats.filter((cat) =>
                        cat.toLowerCase().includes(newText.toLowerCase())
                      )
                    );
                  }
                }}
                onSuggestionClicked={(newValue) => {
                  if (typeof newValue !== "string") {
                    return;
                  }
                  const cat = flattenAssetCats.get(newValue);
                  if (cat) {
                    setZoneAssetSubCat2(cat);
                    setAssetIsSearched(true);
                  } else {
                    setAssetIsSearched(false);
                  }
                }}
              />
              {!assetIsSearched && (
                <>
                  <Text>Please select the type of Asset</Text>
                  <Dropdown
                    options={
                      zone?.assetCategories?.map((aCat: any) => ({
                        key: aCat.id,
                        text: aCat.name,
                      })) ?? []
                    }
                    onChange={(e, option?: IDropdownOption) => {
                      const catId = Number(option?.key ?? "");
                      const cat = zone?.assetCategories?.find(
                        (aCat) => aCat.id === catId
                      );
                      setZoneAssetCat(cat);
                      setZoneAssetSubCat(undefined);
                      setIssueTypeId(undefined);
                    }}
                  ></Dropdown>
                  {zoneAssetCat?.subCategories &&
                  zoneAssetCat?.subCategories.length > 0 ? (
                    <Dropdown
                      options={
                        zoneAssetCat?.subCategories?.map((aCat: any) => ({
                          key: aCat.id,
                          text: aCat.name,
                        })) ?? []
                      }
                      onChange={(e, option?: IDropdownOption) => {
                        const subCatId = Number(option?.key ?? "");
                        const subCat = zoneAssetCat?.subCategories?.find(
                          (aCat) => aCat.id === subCatId
                        );
                        setZoneAssetSubCat(subCat);
                        setIssueTypeId(undefined);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {zoneAssetSubCat?.subCategories &&
                  zoneAssetSubCat?.subCategories.length > 0 ? (
                    <Dropdown
                      options={
                        zoneAssetSubCat?.subCategories?.map((aCat: any) => ({
                          key: aCat.id,
                          text: aCat.name,
                        })) ?? []
                      }
                      onChange={(e, option?: IDropdownOption) => {
                        const subCatId = Number(option?.key ?? "");
                        const subCat = zoneAssetSubCat?.subCategories?.find(
                          (aCat) => aCat.id === subCatId
                        );
                        setZoneAssetSubCat2(subCat);
                        setIssueTypeId(undefined);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
              {zoneAssetSubCat?.issueTypes &&
              zoneAssetSubCat?.issueTypes.length > 0 ? (
                <>
                  <Text>Please select the type of issue</Text>
                  <Dropdown
                    options={
                      zoneAssetSubCat?.issueTypes?.map((issueType: any) => ({
                        key: issueType.id,
                        text: issueType.name,
                      })) ?? []
                    }
                    onChange={(e, option?: IDropdownOption) => {
                      setIssueTypeId(Number(option?.key ?? ""));
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              {zoneAssetSubCat2?.issueTypes &&
              zoneAssetSubCat2?.issueTypes.length > 0 ? (
                <>
                  <Text>Please select the type of issue</Text>
                  <Dropdown
                    options={
                      zoneAssetSubCat2?.issueTypes?.map((issueType: any) => ({
                        key: issueType.id,
                        text: issueType.name,
                      })) ?? []
                    }
                    onChange={(e, option?: IDropdownOption) => {
                      setIssueTypeId(Number(option?.key ?? ""));
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </RenderIf>
            <RenderIf condition={!params.zone}>
              <Text>{t("YouAreReporting")}</Text>
              <Stack horizontal>
                <Label style={labelColumnStyle} disabled={true}>
                  {t("Item")}
                </Label>
                <TextField
                  type="text"
                  styles={{ root: { width: "100%" } }}
                  placeholder={asset.name}
                  disabled
                />
              </Stack>
              <Stack horizontal>
                <Label style={labelColumnStyle} disabled={true}>
                  {t("Code")}
                </Label>
                <TextField
                  type="text"
                  styles={{ root: { width: "100%" } }}
                  placeholder={asset.code}
                  disabled
                />
              </Stack>
              {metadata ? (
                <OtherInfo
                  values={asset.otherInfo}
                  metadata={metadata}
                  isSaved={isSaved}
                  otherInfo={asset.otherInfo}
                  readOnly
                  onOtherInfoChanged={() => {}}
                />
              ) : (
                <></>
              )}
            </RenderIf>
            <CameralRoll images={images} onRetake={handleRetake} />
            <Camera onCapture={handleCapture} />
            <Stack style={{ marginTop: "0px" }}>
              {asset.supportedIssues && asset.supportedIssues.length > 0 ? (
                <Dropdown
                  label="Issue"
                  options={asset.supportedIssues.map((si) => ({
                    key: si.id,
                    text: si.name,
                  }))}
                  componentRef={issueTypeInput}
                ></Dropdown>
              ) : (
                <></>
              )}
              <TextField
                label="Describe the issue"
                type="text"
                multiline
                rows={3}
                {...register("comment")}
              />
              <IssueOtherQuestions
                metadataResponse={issueMetadataResponse}
                onOtherInfoChanged={(newValue: string) =>
                  setOtherInfo(newValue)
                }
              />
              <Toggle
                label="Anonymous"
                defaultChecked={anonymous}
                inlineLabel
                onChange={(_, checked) => setAnonymous(!!checked)}
              />
              <TextField
                label="Please enter your name"
                type="text"
                componentRef={nameInput}
                disabled={anonymous}
                errorMessage={
                  status?.message === "Name has invalid arguments"
                    ? status?.message
                    : undefined
                }
                // {...register("name", { required: false })}
              />
              <TextField
                label="Your mobile"
                type="text"
                required={!anonymous}
                componentRef={mobileInput}
                disabled={anonymous}
                // {...register("mobile", { required: false })}
              />
              <TextField
                label="Your email"
                type="text"
                required={!anonymous}
                componentRef={emailInput}
                disabled={anonymous}
                // {...register("email", { required: false })}
              />
              <PrimaryButton
                disabled={isSubmitting}
                onClick={(e) => {
                  if (!puzzleSolved) {
                    showCaptchaModal();
                  } else {
                    handleSubmit(onSubmit)();
                  }
                }}
              >
                Submit
              </PrimaryButton>
              <RenderIf condition={status !== undefined}>
                <MessageBar
                  messageBarType={
                    status?.success
                      ? MessageBarType.success
                      : MessageBarType.error
                  }
                >
                  {`${status?.message}${
                    status?.details?.issueUuid
                      ? `, Issue: ${status?.details?.issueUuid?.substring(
                          0,
                          8
                        )}`
                      : ""
                  }`}
                </MessageBar>
              </RenderIf>
            </Stack>
          </Stack>
        </form>
        <CaptchaDialog
          isModalOpen={isCaptchaDialogOpen}
          hideModal={hideCaptchaModal}
          onSolved={() => {
            setPuzzleSolved(true);
            handleSubmit(onSubmit)();
          }}
        />
      </Stack>
    </>
  );
};

export default Capture;
